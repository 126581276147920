import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  increment,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import omit from "lodash/omit";

import { MatchDraft } from "../types";
import { db } from "../hooks/firebase";
import { apiError, apiSuccess } from "./utils";
import { MATCH_STATUSES } from "../consts";

export const saveMatch = async ({
  managerId,
  matchDraft,
}: {
  managerId: string;
  matchDraft: MatchDraft;
}) => {
  if (matchDraft.teams!.pool.length > 0) {
    return apiError("Pool must be empty");
  }

  const values = omit(matchDraft, ["selectedPlayers"]);

  const match = {
    ...values,
    ...(matchDraft.teams ? { teams: omit(matchDraft.teams, ["pool"]) } : {}),
    managerId,
    createdAt: Date.now(),
    lastUpdated: Date.now(),
    status: MATCH_STATUSES.SCHEDULED,
  };

  console.log("creating match", {
    managerId,
    matchDraft,
    matchFinal: match,
  });

  try {
    const res = await addDoc(collection(db, "matches"), match);

    return apiSuccess<{ matchId: string }>({
      matchId: res.id,
    });
  } catch (error) {
    return apiError(error);
  }
};

export const deleteMatch = async (matchId: string) => {
  const ref = doc(db, "matches", matchId);

  try {
    await deleteDoc(ref);

    return apiSuccess<{ matchId: string }>({
      matchId,
    });
  } catch (error) {
    return apiError(error);
  }
};

export const updateMatch = async ({
  matchId,
  matchUpdates,
}: {
  matchId: string;
  matchUpdates: Partial<MatchDraft>;
}) => {
  const ref = doc(db, "matches", matchId);

  if (matchUpdates?.teams && matchUpdates.teams.pool?.length > 0) {
    return apiError("Pool must be empty");
  }

  const values = omit(matchUpdates, ["selectedPlayers"]);

  const match = {
    ...values,
    lastUpdated: Date.now(),
    ...(matchUpdates.teams
      ? { teams: omit(matchUpdates.teams, ["pool"]) }
      : {}),
  };

  try {
    await setDoc(ref, match, { merge: true });

    return apiSuccess<{ matchId: string }>({
      matchId,
    });
  } catch (error) {
    return apiError(error);
  }
};

export const completeMatch = async (matchId: string, playerIds: string[]) => {
  try {
    const res = await updateMatch({
      matchId,
      matchUpdates: {
        status: MATCH_STATUSES.COMPLETED,
      },
    });

    if (res.success) {
      const batch = writeBatch(db);

      playerIds.forEach((playerId) => {
        const playerRef = doc(db, "players", playerId);

        batch.update(playerRef, {
          entries: increment(-1),
          lastPlayedAt: Date.now(),
        });
      });

      await batch.commit();
    } else {
      throw new Error("Failed to update match status");
    }
  } catch (error) {
    console.error("completeMatch", error);
    return apiError(error);
  }
};
